import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Editor } from "primereact/editor";
import "react-quill/dist/quill.bubble.css";
import axios from "axios";

const baseURL = "https://api.elcodamics.com/productTable.php";

const ProductsPage = () => {
	const [text, setText] = useState("");

	const [productId, setproductId] = useState(1);
	const [content_1, setContent_1] = useState("");
	const [content_2, setContent_2] = useState("");
	const [template_1, setTemplate_1] = useState("");
	const [template_1_within, setTemplate_1_within] = useState("");
	const [template_2, setTemplate_2] = useState("");
	const [template_2_within, setTemplate_2_within] = useState("");
	const [secretkey, setSecretkey] = React.useState("finacselcapikey");

	React.useEffect(() => {
		axios.get(baseURL).then((response) => {
			setText(response.data[0]);
		});
	}, []);

	const handleSave = () => {
		const updatedData = {
			productId,
			content_1,
			content_2,
			template_1,
			template_1_within,
			template_2,
			template_2_within,
			secretkey,
		};

		axios
			.put(baseURL, updatedData)
			.then((response) => {
				// Handle success, you might want to update the state or show a success message
				console.log("Data saved successfully", response);
			})
			.catch((error) => {
				// Handle error, you might want to show an error message
				console.error("Error saving data:", error);
			});
	};

	return (
		<Box
			sx={{
				width: "80%",
				bgcolor: "#ffffff",
				borderRadius: "10px 10px 10px 10px",
				padding: "2%",
			}}
		>
			<Box sx={{ bgcolor: "#f7f7f7", borderRadius: "10px 10px 10px 10px" }}>
				<Typography
					variant='h2'
					style={{ fontSize: "20px", marginBottom: "1%", padding: "2%" }}
				>
					Title : Products Page
				</Typography>
			</Box>

			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Body - Title
				</Typography>
				<Editor
					value={text.content_1}
					onTextChange={(e) => setContent_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Product - Description
				</Typography>
				<Editor
					value={text.content_2}
					onTextChange={(e) => setContent_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Product One - Button InnerHTML
				</Typography>
				<Editor
					value={text.template_1}
					onTextChange={(e) => setTemplate_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Product One - Description
				</Typography>
				<Editor
					value={text.template_1_within}
					onTextChange={(e) => setTemplate_1_within(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Product Two - InnerHTML
				</Typography>
				<Editor
					value={text.template_2}
					onTextChange={(e) => setTemplate_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Product Two - Description
				</Typography>
				<Editor
					value={text.template_2_within}
					onTextChange={(e) => setTemplate_2_within(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
			</Box>
			<Box
				style={{
					display: "flex",
					justifyContent: "flex-end",
					marginTop: "10px",
				}}
			>
				<Button
					variant='contained'
					color='primary'
					onClick={() => handleSave()}
				>
					Save
				</Button>
			</Box>
		</Box>
	);
};

export default ProductsPage;
