import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import axios from "axios";
import logo from "../assets/finacsLogo.png";
import { useNavigate } from "react-router-dom";
import "../../App.css";

const baseURL = "https://api.elcodamics.com/login.php";

const LoginPage = (props) => {
	const history = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [responseMessage, setResponseMessage] = useState(""); // New state for the response message

	const handleLogin = (event) => {
		event.preventDefault(); // Prevents the default form submission

		// Sending Axios request to baseURL with email and password
		axios
			.post(baseURL, { email, password })
			.then((response) => {
				console.log("Login successful", response.data);
				const authToken = response.data.token;
				localStorage.setItem("auth-token", authToken);
				setResponseMessage("Login successful");
				setTimeout(() => {
					window.location.href = "/";
				}, 2000);
			})
			.catch((error) => {
				console.error("Login failed", error);
				setResponseMessage("Login failed. Please check your credentials.");
			});
	};

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};

	return (
		<section className='login'>
			<div className='login-box'>
				<div className='cover-img'>
					<img src={logo} width='50%' alt='Logo' />
				</div>
				<form className='formcontainer' onSubmit={handleLogin}>
					<TextField
						id='outlined-basic'
						label='email'
						variant='outlined'
						name='username'
						value={email}
						onChange={handleEmailChange}
					/>
					<TextField
						id='outlined-basic'
						label='password'
						variant='outlined'
						type='password'
						value={password}
						onChange={handlePasswordChange}
					/>
					<Button variant='contained' type='submit'>
						Login
					</Button>
					<Typography
						variant='body1'
						color={responseMessage.includes("successful") ? "green" : "red"}
					>
						{responseMessage}
					</Typography>
				</form>
			</div>
		</section>
	);
};

export default LoginPage;
