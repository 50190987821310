import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Editor } from "primereact/editor";
import "react-quill/dist/quill.bubble.css";
import axios from "axios";

const baseURL = "https://api.elcodamics.com/moduleTable.php";

const ModulesPage = () => {
	const [text, setText] = useState("");
	const [moduleId, setModuleId] = useState(1);

	const [content_1, setContent_1] = useState("");
	const [cont1_para_1, setCont1_para_1] = useState("");
	const [cont1_para_2, setCont1_para_2] = useState("");
	const [cont1_para_3, setCont1_para_3] = useState("");
	const [cont1_para_4, setCont1_para_4] = useState("");

	const [content_2, setContent_2] = useState("");
	const [cont2_para_1, setCont2_para_1] = useState("");
	const [cont2_para_2, setCont2_para_2] = useState("");
	const [cont2_para_3, setCont2_para_3] = useState("");
	const [cont2_para_4, setCont2_para_4] = useState("");

	const [content_3, setContent_3] = useState("");
	const [cont3_para_1, setCont3_para_1] = useState("");
	const [cont3_para_2, setCont3_para_2] = useState("");
	const [cont3_para_3, setCont3_para_3] = useState("");
	const [cont3_para_4, setCont3_para_4] = useState("");

	const [content_4, setContent_4] = useState("");
	const [cont4_para_1, setCont4_para_1] = useState("");
	const [cont4_para_2, setCont4_para_2] = useState("");
	const [cont4_para_3, setCont4_para_3] = useState("");
	const [cont4_para_4, setCont4_para_4] = useState("");

	const [content_5, setContent_5] = useState("");
	const [cont5_para_1, setCont5_para_1] = useState("");
	const [cont5_para_2, setCont5_para_2] = useState("");
	const [cont5_para_3, setCont5_para_3] = useState("");
	const [cont5_para_4, setCont5_para_4] = useState("");

	const [content_6, setContent_6] = useState("");
	const [cont6_para_1, setCont6_para_1] = useState("");
	const [cont6_para_2, setCont6_para_2] = useState("");
	const [cont6_para_3, setCont6_para_3] = useState("");
	const [cont6_para_4, setCont6_para_4] = useState("");

	const [content_7, setContent_7] = useState("");
	const [cont7_para_1, setCont7_para_1] = useState("");
	const [cont7_para_2, setCont7_para_2] = useState("");
	const [cont7_para_3, setCont7_para_3] = useState("");
	const [cont7_para_4, setCont7_para_4] = useState("");

	const [content_8, setContent_8] = useState("");
	const [cont8_para_1, setCont8_para_1] = useState("");
	const [cont8_para_2, setCont8_para_2] = useState("");
	const [cont8_para_3, setCont8_para_3] = useState("");
	const [cont8_para_4, setCont8_para_4] = useState("");
	const [secretkey, setSecretkey] = React.useState("finacselcapikey");

	React.useEffect(() => {
		axios.get(baseURL).then((response) => {
			setText(response.data[0]);
		});
	}, []);

	const handleSave = () => {
		const updatedData = {
			moduleId,
			content_1,
			cont1_para_1,
			cont1_para_2,
			cont1_para_3,
			cont1_para_4,
			content_2,
			cont2_para_1,
			cont2_para_2,
			cont2_para_3,
			cont2_para_4,
			content_3,
			cont3_para_1,
			cont3_para_2,
			cont3_para_3,
			cont3_para_4,
			content_4,
			cont4_para_1,
			cont4_para_2,
			cont4_para_3,
			cont4_para_4,
			content_5,
			cont5_para_1,
			cont5_para_2,
			cont5_para_3,
			cont5_para_4,
			content_6,
			cont6_para_1,
			cont6_para_2,
			cont6_para_3,
			cont6_para_4,
			content_7,
			cont7_para_1,
			cont7_para_2,
			cont7_para_3,
			cont7_para_4,
			content_8,
			cont8_para_1,
			cont8_para_2,
			cont8_para_3,
			cont8_para_4,
			secretkey,
		};

		axios
			.put(baseURL, updatedData)
			.then((response) => {
				console.log("Data saved successfully", response);
			})
			.catch((error) => {
				// Handle error, you might want to show an error message
				console.error("Error saving data:", error);
			});
	};

	return (
		<Box
			sx={{
				width: "80%",
				bgcolor: "#ffffff",
				borderRadius: "10px 10px 10px 10px",
				padding: "2%",
			}}
		>
			<Box sx={{ bgcolor: "#f7f7f7", borderRadius: "10px 10px 10px 10px" }}>
				<Typography
					variant='h2'
					style={{ fontSize: "20px", marginBottom: "1%", padding: "2%" }}
				>
					Title : Modules Page
				</Typography>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content_1
				</Typography>
				<Editor
					value={text.content_1}
					onTextChange={(e) => setContent_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 1
				</Typography>
				<Editor
					value={text.cont1_para_1}
					onTextChange={(e) => setCont1_para_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 2
				</Typography>
				<Editor
					value={text.cont1_para_2}
					onTextChange={(e) => setCont1_para_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 3
				</Typography>
				<Editor
					value={text.cont1_para_3}
					onTextChange={(e) => setCont1_para_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 4
				</Typography>
				<Editor
					value={text.cont1_para_4}
					onTextChange={(e) => setCont1_para_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content 2
				</Typography>
				<Editor
					value={text.content_2}
					onTextChange={(e) => setContent_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 1
				</Typography>
				<Editor
					value={text.cont2_para_1}
					onTextChange={(e) => setCont2_para_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 2
				</Typography>
				<Editor
					value={text.cont2_para_2}
					onTextChange={(e) => setCont2_para_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 3
				</Typography>
				<Editor
					value={text.cont2_para_3}
					onTextChange={(e) => setCont2_para_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 4
				</Typography>
				<Editor
					value={text.cont2_para_4}
					onTextChange={(e) => setCont2_para_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content 3
				</Typography>
				<Editor
					value={text.content_3}
					onTextChange={(e) => setContent_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 1
				</Typography>
				<Editor
					value={text.cont3_para_1}
					onTextChange={(e) => setCont3_para_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 2
				</Typography>
				<Editor
					value={text.cont3_para_2}
					onTextChange={(e) => setCont3_para_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 3
				</Typography>
				<Editor
					value={text.cont3_para_3}
					onTextChange={(e) => setCont3_para_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 4
				</Typography>
				<Editor
					value={text.cont3_para_4}
					onTextChange={(e) => setCont3_para_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content 4
				</Typography>
				<Editor
					value={text.content_4}
					onTextChange={(e) => setContent_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 1
				</Typography>
				<Editor
					value={text.cont4_para_1}
					onTextChange={(e) => setCont4_para_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 2
				</Typography>
				<Editor
					value={text.cont4_para_2}
					onTextChange={(e) => setCont4_para_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 3
				</Typography>
				<Editor
					value={text.cont4_para_3}
					onTextChange={(e) => setCont4_para_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 4
				</Typography>
				<Editor
					value={text.cont4_para_4}
					onTextChange={(e) => setCont4_para_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content 5
				</Typography>
				<Editor
					value={text.content_5}
					onTextChange={(e) => setContent_5(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 1
				</Typography>
				<Editor
					value={text.cont5_para_1}
					onTextChange={(e) => setCont5_para_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 2
				</Typography>
				<Editor
					value={text.cont5_para_2}
					onTextChange={(e) => setCont5_para_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 3
				</Typography>
				<Editor
					value={text.cont5_para_3}
					onTextChange={(e) => setCont5_para_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 4
				</Typography>
				<Editor
					value={text.cont5_para_4}
					onTextChange={(e) => setCont5_para_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content 6
				</Typography>
				<Editor
					value={text.content_6}
					onTextChange={(e) => setContent_6(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 1
				</Typography>
				<Editor
					value={text.cont6_para_1}
					onTextChange={(e) => setCont6_para_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 2
				</Typography>
				<Editor
					value={text.cont6_para_2}
					onTextChange={(e) => setCont6_para_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 3
				</Typography>
				<Editor
					value={text.cont6_para_3}
					onTextChange={(e) => setCont6_para_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 4
				</Typography>
				<Editor
					value={text.cont6_para_4}
					onTextChange={(e) => setCont6_para_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content 7
				</Typography>
				<Editor
					value={text.content_7}
					onTextChange={(e) => setContent_7(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 1
				</Typography>
				<Editor
					value={text.cont7_para_1}
					onTextChange={(e) => setCont7_para_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 2
				</Typography>
				<Editor
					value={text.cont7_para_2}
					onTextChange={(e) => setCont7_para_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 3
				</Typography>
				<Editor
					value={text.cont7_para_3}
					onTextChange={(e) => setCont7_para_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 4
				</Typography>
				<Editor
					value={text.cont7_para_4}
					onTextChange={(e) => setCont7_para_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content 8
				</Typography>
				<Editor
					value={text.content_8}
					onTextChange={(e) => setContent_8(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 1
				</Typography>
				<Editor
					value={text.cont8_para_1}
					onTextChange={(e) => setCont8_para_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 2
				</Typography>
				<Editor
					value={text.cont8_para_2}
					onTextChange={(e) => setCont8_para_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 3
				</Typography>
				<Editor
					value={text.cont8_para_3}
					onTextChange={(e) => setCont8_para_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Content Description 4
				</Typography>
				<Editor
					value={text.cont8_para_4}
					onTextChange={(e) => setCont8_para_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ModulesPage;
