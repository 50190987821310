import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Editor } from "primereact/editor";
import "react-quill/dist/quill.bubble.css";
import axios from "axios";

const baseURL = "https://api.elcodamics.com/homeTable.php";

const HomePage = () => {
	const [post, setPost] = useState({});
	const [content_1, setContent_1] = useState("");
	const [content_2, setContent_2] = useState("");
	const [content_3, setContent_3] = useState("");
	const [content_4, setContent_4] = useState("");
	const [content_5, setContent_5] = useState("");
	const [content_6, setContent_6] = useState("");
	const [homeId, setHomeId] = useState("");
	const [secretkey, setSecretkey] = React.useState("finacselcapikey");

	useEffect(() => {
		axios.get(baseURL).then((response) => {
			setPost(response.data[0]);
		});
	}, []);

	useEffect(() => {
		const delay = 500;
		const timeoutId = setTimeout(() => {
			setContent_1(post.content_1);
			setContent_2(post.content_2);
			setContent_3(post.content_3);
			setContent_4(post.content_4);
			setContent_5(post.content_5);
			setContent_6(post.content_6);
			setHomeId(1);
		}, delay);
		return () => clearTimeout(timeoutId);
	}, [post]);

	const handleSave = () => {
		const updatedData = {
			content_1,
			content_2,
			content_3,
			content_4,
			content_5,
			content_6,
			homeId,
			secretkey,
		};

		axios
			.put(baseURL, updatedData)
			.then((response) => {
				// Handle success, you might want to update the state or show a success message
				console.log("Data saved successfully");
			})
			.catch((error) => {
				// Handle error, you might want to show an error message
				console.error("Error saving data:", error);
			});
	};

	return (
		<Box
			sx={{
				width: "80%",
				bgcolor: "#ffffff",
				borderRadius: "10px 10px 10px 10px",
				padding: "2%",
			}}
		>
			<Box sx={{ bgcolor: "#f7f7f7", borderRadius: "10px 10px 10px 10px" }}>
				<Typography
					variant='h2'
					style={{ fontSize: "20px", marginBottom: "1%", padding: "2%" }}
				>
					Title : Home Page
				</Typography>
			</Box>

			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Body - Line 1
				</Typography>
				<Editor
					value={content_1}
					onTextChange={(e) => setContent_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Body - Line 2
				</Typography>
				<Editor
					value={content_2}
					onTextChange={(e) => setContent_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Body - Line 3
				</Typography>
				<Editor
					value={content_3}
					onTextChange={(e) => setContent_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px", display: "none" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%", display: "none" }}
				>
					Schools
				</Typography>
				<Editor
					value={content_4}
					onTextChange={(e) => setContent_4(e.textValue)}
					style={{
						height: "10vh",
						borderRadius: "0px 0px 10px 10px",
					}}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px", display: "none" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Members
				</Typography>
				<Editor
					value={content_5}
					onTextChange={(e) => setContent_5(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px", display: "none", display: "none" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Countries
				</Typography>
				<Editor
					value={content_6}
					onTextChange={(e) => setContent_6(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default HomePage;
