import React, { useState } from "react";
import {
	Box,
	Container,
	Typography,
	TextField,
	Button,
	Input,
	Card,
	CardMedia,
	CardContent,
} from "@mui/material";
import Sidebar from "../sidebar/sidebar";
import { Editor } from "primereact/editor";
import "react-quill/dist/quill.bubble.css";

const StoryPage = () => {
	const [text, setText] = useState("");

	return (
		<Box
			sx={{
				width: "80%",
				bgcolor: "#ffffff",
				borderRadius: "10px 10px 10px 10px",
				padding: "2%",
			}}
		>
			<Box sx={{ bgcolor: "#f7f7f7", borderRadius: "10px 10px 10px 10px" }}>
				<Typography
					variant='h2'
					style={{ fontSize: "20px", marginBottom: "1%", padding: "2%" }}
				>
					Title : Story Page
				</Typography>
			</Box>

			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Story - Title
				</Typography>
				<Editor
					value={text}
					onTextChange={(e) => setText(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => alert("Text saved!")}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Story - Stanza 1
				</Typography>
				<Editor
					value={text}
					onTextChange={(e) => setText(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => alert("Text saved!")}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Story - Stanza 2
				</Typography>
				<Editor
					value={text}
					onTextChange={(e) => setText(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => alert("Text saved!")}
					>
						Save
					</Button>
				</Box>
			</Box>

			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Button - InnerHTML
				</Typography>
				<Editor
					value={text}
					onTextChange={(e) => setText(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => alert("Text saved!")}
					>
						Save
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default StoryPage;
