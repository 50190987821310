import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon"; // Import ListItemIcon
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import GradeIcon from "@mui/icons-material/Grade";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import MapIcon from "@mui/icons-material/Map";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import { Box, Typography, useMediaQuery, Grid } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LanguageIcon from "@mui/icons-material/Language";
import NotesIcon from "@mui/icons-material/Notes";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import Logo from "../assets/finacsLogo.png";

const sidebarWidth = "30vh";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
	width: sidebarWidth,
	flexShrink: 0,
	"& .MuiDrawer-paper": {
		width: sidebarWidth,
	},
}));

const Sidebar = () => {
	const [open, setOpen] = useState(false);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const handleToggle = () => {
		setOpen(!open);
	};

	const handleLogout = () => {
		// Clear the authentication token in localStorage
		localStorage.removeItem("auth-token");
		window.location.reload();
	};

	const SidebarContent = (
		<Box sx={{ backgroundColor: "#ffffff", minHeight: "100vh" }}>
			<Grid
				container
				alignItems='center'
				justifyContent='space-between'
				sx={{ borderBottom: 1, borderColor: "divider", paddingBottom: 1, p: 2 }}
			>
				<Grid item xs={12} sm={4} sx={{ width: "100%" }}>
					<img src={Logo} width='150px' height='50px' alt='logo' />
				</Grid>

				{/* <Grid item xs={12} sm={8}>
          <Typography>Finacs CMS</Typography>
        </Grid> */}
			</Grid>
			<Box sx={{ px: 4, py: 3 }}>
				<Typography sx={{ color: "#ff7d0c" }}>Pages</Typography>
				<List>
					<ListItem sx={{ padding: "0px", marginBottom: "5%" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<HomeIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<Link to='/' style={{ textDecoration: "none", color: "#004c99" }}>
							<ListItemText primary='Home' />
						</Link>
					</ListItem>
					<ListItem sx={{ padding: "0px", marginBottom: "5%" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<InfoIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<Link
							to='/about'
							style={{ textDecoration: "none", color: "#004c99" }}
						>
							<ListItemText primary='About' />
						</Link>
					</ListItem>
					<ListItem sx={{ padding: "0px", marginBottom: "5%" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<MapIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<Link
							to='/map'
							style={{ textDecoration: "none", color: "#004c99" }}
						>
							<ListItemText primary='Map' />
						</Link>
					</ListItem>

					<ListItem sx={{ padding: "0px", marginBottom: "5%" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<ViewModuleIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<Link
							to='/modules'
							style={{ textDecoration: "none", color: "#004c99" }}
						>
							<ListItemText primary='Modules' />
						</Link>
					</ListItem>

					<ListItem sx={{ padding: "0px", marginBottom: "5%" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<StorefrontIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<Link
							to='/products'
							style={{ textDecoration: "none", color: "#004c99" }}
						>
							<ListItemText primary='Products' />
						</Link>
					</ListItem>

					<ListItem sx={{ padding: "0px" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<GradeIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<Link
							to='/features'
							style={{ textDecoration: "none", color: "#004c99" }}
						>
							<ListItemText primary='Features' />
						</Link>
					</ListItem>
					<ListItem sx={{ padding: "0px" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<OndemandVideoIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<Link
							to='/demo'
							style={{ textDecoration: "none", color: "#004c99" }}
						>
							<ListItemText primary='Demo' />
						</Link>
					</ListItem>
					<ListItem sx={{ padding: "0px" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<ContactMailIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<Link
							to='/contactus'
							style={{ textDecoration: "none", color: "#004c99" }}
						>
							<ListItemText primary='ContactUs' />
						</Link>
					</ListItem>
				</List>
			</Box>

			<Box sx={{ px: 4 }}>
				<Typography sx={{ color: "#ff7d0c" }}>General Settings</Typography>
				<List>
					<a href='https://finacs.com' target='blank'>
						<ListItem sx={{ padding: "0px", marginBottom: "5%" }}>
							<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
								<VisibilityIcon sx={{ color: "#004c99" }} />
							</ListItemIcon>
							<ListItemText primary='View Website' sx={{ color: "#004c99" }} />
						</ListItem>
					</a>
					{/* <ListItem sx={{ padding: "0px", marginBottom: "5%" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<LanguageIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<ListItemText primary='Website Info' sx={{ color: "#004c99" }} />
					</ListItem> */}
					{/* <ListItem sx={{ padding: "0px", marginBottom: "5%" }}>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<NotesIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<ListItemText primary='Notes' sx={{ color: "#004c99" }} />
					</ListItem> */}
				</List>
			</Box>

			<Box sx={{ px: 4 }}>
				<Typography sx={{ color: "#ff7d0c" }}>Additional Settings</Typography>
				<List>
					<a href='mailto:hello@zlancreations.com'>
						<ListItem sx={{ padding: "0px", marginBottom: "5%" }}>
							<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
								<DeveloperBoardIcon sx={{ color: "#004c99" }} />
							</ListItemIcon>
							<ListItemText
								primary='Developer Support'
								sx={{ color: "#004c99" }}
							/>
						</ListItem>
					</a>
					<ListItem
						sx={{ padding: "0px", marginBottom: "5%", cursor: "pointer" }}
						onClick={handleLogout}
					>
						<ListItemIcon sx={{ minWidth: "0px", marginRight: "10%" }}>
							<ExitToAppIcon sx={{ color: "#004c99" }} />
						</ListItemIcon>
						<ListItemText primary='Sign Out' sx={{ color: "#004c99" }} />
					</ListItem>
				</List>
			</Box>
		</Box>
	);

	return (
		<Box sx={{ padding: 2 }}>
			{isMobile ? (
				<IconButton onClick={handleToggle}>
					<MenuIcon />
				</IconButton>
			) : (
				<StyledDrawer variant='permanent' anchor='left' open>
					{SidebarContent}
				</StyledDrawer>
			)}
			{isMobile && (
				<Drawer variant='temporary' open={open} onClose={handleToggle}>
					{SidebarContent}
				</Drawer>
			)}
		</Box>
	);
};

export default Sidebar;
