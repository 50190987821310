import React, { useState } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { Box } from "@mui/material";
import Sidebar from "./components/sidebar/sidebar";
import HomePage from "./components/pages/HomePage";
import MapPage from "./components/pages/MapPage";
import AboutPage from "./components/pages/AboutPage";
import ProductsPage from "./components/pages/ProductsPage";
import StoryPage from "./components/pages/StoryPage";
import FeaturesPage from "./components/pages/FeaturesPage";
import DemoPage from "./components/pages/DemoPage";
import ModulesPage from "./components/pages/ModulesPage";
import ContactPage from "./components/pages/ContactPage";
import LoginPage from "./components/pages/login";

function App() {
	const [userAuthenticated, setUserAuthenticated] = useState(() => {
		// Get the authentication token from localStorage
		const authToken = localStorage.getItem("auth-token");

		// Check if the token is null and return the appropriate value
		return authToken !== null;
	});
	const shouldRenderSidebar =
		window.location.pathname !== "/login" && userAuthenticated;

	// Protecting route: Redirect to login if the user is not authenticated
	const ProtectedRoute = ({ element }) => {
		if (userAuthenticated) {
			return element;
		} else {
			return <Navigate to='/login' />;
		}
	};

	return (
		<Router>
			<Box>
				<Box
					sx={{
						display: "flex",
						bgcolor: shouldRenderSidebar ? "#F7F7F7" : "transparent",
						minHeight: "95vh",
					}}
					className='main-container'
				>
					{shouldRenderSidebar && <Sidebar />}
					<Box
						sx={{
							display: shouldRenderSidebar ? "flex" : "block",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
							padding: shouldRenderSidebar ? "3%" : "0%",
						}}
					>
						<Routes>
							<Route
								path='/'
								element={<ProtectedRoute element={<HomePage />} />}
							/>
							<Route
								path='/login'
								element={
									<LoginPage
										userAuthenticated={userAuthenticated}
										setUserAuthenticated={setUserAuthenticated}
									/>
								}
							/>
							<Route
								path='/about'
								element={<ProtectedRoute element={<AboutPage />} />}
							/>
							{/* Add ProtectedRoute to other routes that require authentication */}
							<Route
								path='/map'
								element={<ProtectedRoute element={<MapPage />} />}
							/>
							<Route
								path='/modules'
								element={<ProtectedRoute element={<ModulesPage />} />}
							/>
							<Route
								path='/products'
								element={<ProtectedRoute element={<ProductsPage />} />}
							/>
							<Route
								path='/story'
								element={<ProtectedRoute element={<StoryPage />} />}
							/>
							<Route
								path='/features'
								element={<ProtectedRoute element={<FeaturesPage />} />}
							/>
							<Route
								path='/demo'
								element={<ProtectedRoute element={<DemoPage />} />}
							/>
							<Route
								path='/contactus'
								element={<ProtectedRoute element={<ContactPage />} />}
							/>
						</Routes>
					</Box>
				</Box>
			</Box>
		</Router>
	);
}

export default App;
