import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Editor } from "primereact/editor";
import "react-quill/dist/quill.bubble.css";
import axios from "axios";

const baseURL = "https://api.elcodamics.com/contactsTable.php";

const ContactPage = () => {
	const [text, setText] = useState("");

	const [contactId, setContactId] = useState(1);
	const [content_1, setContent_1] = useState("");
	const [content_2, setContent_2] = useState("");
	const [content_3, setContent_3] = useState("");
	const [content_4, setContent_4] = useState("");
	const [secretkey, setSecretkey] = React.useState("finacselcapikey");

	React.useEffect(() => {
		axios.get(baseURL).then((response) => {
			setText(response.data[0]);
		});
	}, []);

	const handleSave = () => {
		const updatedData = {
			contactId,
			content_1,
			content_2,
			content_3,
			content_4,
			secretkey,
		};

		axios
			.put(baseURL, updatedData)
			.then((response) => {
				// Handle success, you might want to update the state or show a success message
				console.log("Data saved successfully", response);
			})
			.catch((error) => {
				// Handle error, you might want to show an error message
				console.error("Error saving data:", error);
			});
	};

	return (
		<Box
			sx={{
				width: "80%",
				bgcolor: "#ffffff",
				borderRadius: "10px 10px 10px 10px",
				padding: "2%",
			}}
		>
			<Box sx={{ bgcolor: "#f7f7f7", borderRadius: "10px 10px 10px 10px" }}>
				<Typography
					variant='h2'
					style={{ fontSize: "20px", marginBottom: "1%", padding: "2%" }}
				>
					Title : ContactUs Page
				</Typography>
			</Box>
			<Box style={{ marginTop: "20px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					ContactUs - Title
				</Typography>
				<Editor
					value={text.content_1}
					onTextChange={(e) => setContent_1(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Required Fields
				</Typography>
				<Editor
					value={text.content_2}
					onTextChange={(e) => setContent_2(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Address Line 1
				</Typography>
				<Editor
					value={text.content_3}
					onTextChange={(e) => setContent_3(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box style={{ marginTop: "10px" }}>
				<Typography
					variant='h5'
					style={{ fontSize: "20px", marginBottom: "1%" }}
				>
					Address Line 2
				</Typography>
				<Editor
					value={text.content_4}
					onTextChange={(e) => setContent_4(e.textValue)}
					style={{ height: "10vh", borderRadius: "0px 0px 10px 10px" }}
				/>
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleSave()}
					>
						Save
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ContactPage;
